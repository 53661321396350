<template>
  <div>
    <v-card>
      <v-card-title>SOLICITUD DE MATERIALES</v-card-title>
      <v-card-text>
        <div class="row align-items-center">
          <div class="col-md-9"></div>
          <div class="col-md-2">
            <p style="text-align:right;margin-top:25px">
              {{ botones[0].tooltip }}
            </p>
          </div>
          <div class="col-md-1 text-left">


            <v-btn
      class="mx-2"
      fab
      dark
      small
      color="indigo"
      @click="agregarSolicitud"
    >
      <v-icon dark>
        mdi-plus
      </v-icon>
    </v-btn>
          </div>
        </div>
      </v-card-text>
    </v-card>
    <div class="card">
      <div class="card-header card-header-primary card-header-icon">
        <div :class="`card-icon ${color_icono}`">
          <i class="material-icons" v-show="icono">{{ icono }}</i>
        </div>
        <h4 class="card-title">
          <div class="row">
            <div class="col-md-4">
              {{ titulo }}
            </div>
          </div>
          <div class="row">
            <div class="col-md-3"></div>
            <div class="col-md-2">
              <v-text-field
                placeholder="Número folio"
                v-model="folio_filtred"
                type="text"
                style="max-width:180px"
              />
            </div>
            <div class="col-md-2">
              <v-menu
                v-model="menu1"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="inicio_formated"
                    label="Fecha de Inicio"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="inicio"
                  @input="menu1 = false"
                  locale="es-cl"
                  prev-icon="chevron_left"
                  next-icon="chevron_right"
                ></v-date-picker>
              </v-menu>
            </div>
            <div class="col-md-2">
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="termino_formated"
                    label="Fecha de termino"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :disabled="!inicio"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="termino"
                  @input="menu2 = false"
                  locale="es-cl"
                  prev-icon="chevron_left"
                  next-icon="chevron_right"
                ></v-date-picker>
              </v-menu>
            </div>
            <div class="col-md-3">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="mx-1 mt-2"
                    fab
                    dark
                    small
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    @click="loadSolicitudMateriales(true)"
                  >
                    <v-icon large style="color:white">
                      mdi-magnify
                    </v-icon>
                  </v-btn>
                </template>
                <span>Buscar</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="mx-1 mt-2"
                    fab
                    dark
                    small
                    color="warning"
                    v-bind="attrs"
                    v-on="on"
                    @click="limpiar()"
                  >
                    <v-icon large style="color:white">
                      mdi-broom
                    </v-icon>
                  </v-btn>
                </template>
                <span>Limpiar Filtro</span>
              </v-tooltip>
            </div>
            <!-- <div class="col-md-2">
                            <v-text-field
                                v-model="folio_filtred"
                                placeholder="Escriba Folio"
                                append-icon="mdi-magnify"
                                single-line
                                hide-details
                            ></v-text-field>
                        </div> -->
            <!-- <div class="col-md-2">
                            <v-text-field
                                v-model="item_filtred"
                                placeholder="Escriba Item"
                                append-icon="mdi-magnify"
                                single-line
                                hide-details
                            ></v-text-field>
                        </div> -->
            <div class="row">
              <div class="col-md-4"></div>
              <div class="col-md-4" style="text-align:center">
                <p style="font-size:15px">
                  (pinchar en el botón limpiar
                  <v-btn
                    style="width:26px;height:26px;cursor:default"
                    fab
                    dark
                    small
                    dense
                    color="warning"
                  >
                    <v-icon large style="color:white;font-size:25px">
                      mdi-broom
                    </v-icon>
                  </v-btn>
                  para listar todas las solicitudes)
                </p>
              </div>
              <div class="col-md-4"></div>
            </div>
          </div>
        </h4>
      </div>

      <div class="card-body">
        <spinner v-if="spinner"></spinner>
        <v-simple-table :height="table_height" class="table-container">
          <template v-slot:default>
            <thead>
              <tr>
                <th
                  v-for="(data, index) in filterDataHeader"
                  :key="index"
                  @click="sortTable(data.value)"
                  class="text-center"
                >
                  {{ data.text }}
                  <v-icon small style="color:#ffff;cursor:pointer">
                    mdi-{{ iconOrder(data.value) }}
                  </v-icon>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, dato, index) in sortedList"
                :key="index"
                class="text-center"
              >
                <td v-if="columnaVisible('wellboat')">
                  {{ item.wellboat }}
                </td>
                <td v-if="columnaVisible('folio')">
                  {{ `${item.centro_costo}-${item.folio}` }}
                </td>
                <td v-if="columnaVisible('area')">
                  {{ item.area }}
                </td>
                <td v-if="columnaVisible('categoria')">
                  {{ item.categoria }}
                </td>
                <td v-if="columnaVisible('material')">
                  {{ item.material_codigo }}
                </td>
                <td v-if="columnaVisible('descripcion')">
                  {{ item.descripcion }}
                </td>
                <td v-if="columnaVisible('fecha_solicitud')">
                  {{ item.fecha_solicitud }}
                </td>
                <td v-if="columnaVisible('cantidad')">
                  {{ item.cantidad }}
                </td>
                <td
                  v-if="columnaVisible('revisor_nombre_apellido')"
                  style="width=50px"
                >
                  {{ item.revisor_nombre_apellido }}
                </td>
                <td
                  v-if="columnaVisible('revisor_fecha_aprob')"
                  style="width=50px"
                >
                  {{ item.revisor_fecha_aprob | format_date_spanish }}
                </td>
                <td v-if="columnaVisible('revisor_cantidad_aprob')">
                  {{ item.revisor_cantidad_aprob }}
                </td>
                <td
                  v-if="columnaVisible('aprobador_nombre_apellido')"
                  style="width=50px"
                >
                  {{ item.aprobador_nombre_apellido }}
                </td>
                <td
                  v-if="columnaVisible('aprobador_fecha_aprob')"
                  style="width=50px"
                >
                  {{ item.aprobador_fecha_aprob | format_date_spanish }}
                </td>
                <td v-if="columnaVisible('aprobador_cantidad_aprob')">
                  {{ item.aprobador_cantidad_aprob }}
                </td>
                <td v-if="columnaVisible('archivo_adjunto')">
                  <a
                    @click="downloadFile(item.archivo)"
                    target="_blank"
                    class="ma-2"
                  >
                    <v-icon
                      large
                      v-if="
                        item && item.archivo && item.archivo.includes('.pdf')
                      "
                      style="color:#ef233c"
                    >
                      mdi-file-pdf-box
                    </v-icon>
                    <v-icon
                      large
                      v-else-if="item.archivo != null"
                      style="color:#52b788"
                    >
                      mdi-file-image
                    </v-icon>
                  </a>
                </td>
                <td v-if="columnaVisible('estado')">
                  {{ item.estado }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </div>

    <!-- <datatable :cabeceras="listHeaders" :items="listBody" titulo="CONSOLIDADO"
            label="Escriba Para Buscar Solicitud" icono="list" color_icono="azul" :tachar="false"
            :acciones="[]" @editar="editSolicitudMaterial($event)" @eliminar="deleteSolicitudMaterial($event)">
        </datatable> -->
    <v-alert
      v-if="emptyData && load_data"
      border="right"
      color="blue-grey"
      dark
    >
      No hay solicitudes de materiales...
    </v-alert>
    <v-snackbar v-model="snackbar" :timeout="5000" :color="color">
      {{ validation_message }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
// import DatePicker from "vue2-datepicker";
// import DataTable from '@/components/base/DataTable.vue';

export default {
  data: () => ({
    data_header: [
      //Solicitante
      { text: "Wellboat", value: "wellboat", visible: [6, 7, 8] },
      { text: "Folio", value: "folio", visible: [6, 7, 8] },
      { text: "Área", value: "area", visible: [6, 7, 8] },
      { text: "Categoría", value: "categoria", visible: [6, 7, 8] },
      { text: "Ítem", value: "material", visible: [6, 7, 8] },
      { text: "Descripción", value: "descripcion", visible: [6, 7, 8] },
      { text: "Fecha solicitud", value: "fecha_solicitud", visible: [6, 7, 8] },

      { text: "Cantidad", value: "cantidad", visible: [6, 7, 8] },

      //Revisor
      //{text: 'Cantidad aprobada', value:'cantidad_aprobada', visible:[7,8], type:'number'},
      { text: "Revisor", value: "revisor_nombre_apellido", visible: [7, 8] },
      {
        text: "Fecha Revision",
        value: "revisor_fecha_aprob",
        visible: [7, 8],
        type: "date",
      },
      {
        text: "Cantidad Aprobada",
        value: "revisor_cantidad_aprob",
        visible: [7, 8],
      },

      //Aprobador
      { text: "Aprobador", value: "aprobador_nombre_apellido", visible: [8] },
      {
        text: "Fecha Aprobador",
        value: "aprobador_fecha_aprob",
        visible: [8],
        type: "date",
      },
      {
        text: "Cantidad Autorizada",
        value: "aprobador_cantidad_aprob",
        visible: [8],
      },

      //Solicitante
      { text: "Archivo adjunto", value: "archivo_adjunto", visible: [6, 7, 8] },
      { text: "Estado", value: "estado", visible: [6, 7, 8] },
    ],
    sortBy: "", // Columna actual por la que se ordena
    sortDesc: false, // Dirección de la ordenación (ascendente o descendente)
    table_height: 500,
    spinner: false,
    titulo: "CONSOLIDADO",
    color_icono: "azul",
    icono: "list",
    title: "Nueva Solicitud de Material",
    color: "green",
    snackbar: false,
    errors: [],
    validation_message: "",
    search: null,
    file: null,
    dialog: false,
    upload: false,
    changeState: false,
    empresas: [],
    colors: [],
    sizes: [],
    state: null,
    states: [],
    index: -1,
    inicio: "",
    termino: "",
    menu1: false,
    menu2: false,
    load_data: false,
    item: {
      id: 0,
      nombre: "",
      categorias_id: null,
      unidad_medida: "",
    },
    listBody: [],
    botones: [
      { boton: "crear", tooltip: "Nueva Solicitud" },
      // { boton: 'cargar', tooltip: 'Carga Masiva' },
      //{boton:'eliminar',tooltip:'Borrar Todo'}
    ],
    folio_filtred: "",
    item_filtred: "",
    // Datos iniciales de la tabla
    tableData: [],
    // Indica si se están cargando más datos
    //loading: false,
    page: 1,
    last_page: null,
    data_filtered: false,
  }),
  mounted() {
    this.loadSolicitudMateriales();
    if (window.innerHeight < 768) {
      this.table_height = 300;
    }
    document
      .querySelector(".v-data-table__wrapper")
      .addEventListener("scroll", (event) => {
        // console.log(`scrollHeight: ${event.target.scrollHeight}, scrollTop: ${event.target.scrollTop}, clientHeight: ${event.target.clientHeight}`)
        // console.log(event.target.scrollHeight - event.target.scrollTop - event.target.clientHeight);
        //if (this.data_filtered) return;
        if (
          event.target.scrollHeight -
            event.target.scrollTop -
            event.target.clientHeight <
          1
        ) {
          // console.log(this.last_page);
          if (this.last_page != null && this.page < this.last_page) {
            this.page++;
            this.loadSolicitudMateriales();
          }
        }
      });
  },
  computed: {
    ...mapState(["loading", "base_url", "headers", "user", "storage_url"]),
    // filtroSolicitudes:(data)=>{
    //     let solicitudes = [];
    //     //let filtro1 = data.folio_filtred!=""?(d=>d.folio == data.folio_filtred):(d=>d);
    //     let filtro2 = data.item_filtred!=""?(d=>d.material.toUpperCase().includes(data.item_filtred.toUpperCase())):(d=>d);
    //     solicitudes = data.listBody
    //         //.filter(filtro1)
    //         .filter(filtro2)
    //     return solicitudes
    // },
    filterDataHeader(data) {
      return data.data_header.filter((d) => {
        return this.columnaVisible(d.value);
      });
    },
    inicio_formated() {
      return this.formatDate(this.inicio);
    },
    termino_formated() {
      return this.formatDate(this.termino);
    },
    emptyData: (data) => {
      return data.listBody.length > 0 ? false : true;
    },
    sortedList: (data) => {
      // Copia la lista original para no modificarla directamente
      const sortedList = data.listBody;

      if (data.sortBy) {
        sortedList.sort((a, b) => {
          let valA = a[data.sortBy];
          let valB = b[data.sortBy];
          if (valA === null && valB !== null) {
            return data.sortDesc ? -1 : 1;
          }
          if (valA !== null && valB === null) {
            return data.sortDesc ? 1 : -1;
          }
          if (valA !== null && valB !== null) {
            if (valA < valB) {
              return data.sortDesc ? 1 : -1;
            }
            if (valA > valB) {
              return data.sortDesc ? -1 : 1;
            }
          }
          return 0;
        });
      }

      return sortedList;
    },
  },
  // watch: {
  //     //crear un watch que reordene listBody segun corresponda el sortBy
  //     listBody(newSortBy){
  //         if (this.sortBy) {
  //             newSortBy
  //             .sort((a, b) => {
  //                 if (a[this.sortBy] !== null && b[this.sortBy] !== null) {
  //                     const wellboatA = a[this.sortBy];
  //                     const wellboatB = b[this.sortBy];

  //                     if (wellboatA < wellboatB) {
  //                     return this.sortDesc ? 1 : -1;
  //                     }
  //                     if (wellboatA > wellboatB) {
  //                     return this.sortDesc ? -1 : 1;
  //                     }
  //                 }
  //                 return 0;
  //             });
  //         }
  //     },
  // },
  methods: {
    iconOrder(column) {
      return this.sortBy == column
        ? this.sortDesc
          ? "arrow-down"
          : "arrow-up"
        : "swap-vertical";
    },
    sortTable(column) {
      if (column === this.sortBy) {
        this.sortDesc = !this.sortDesc;
      } else {
        this.sortBy = column;
        this.sortDesc = false;
      }
    },
    downloadFile(archivo) {
      /*NO BORRAR, FUNCIONA EN LOCAL COMO EN PRODUCCION */
      let link = `${this.storage_url}solicitudes/${archivo}`;
      console.log(link);
      window.open(link);
    },
    async loadSolicitudMateriales(primera_pagina = false) {
      this.spinner = true;
      this.mostrarLoading("Cargando listado de solicitudes...");
      //let url = `${this.base_url}solicitudes/estado_solicitud/${0}`;
      let url = `${this.base_url}consolidado/solicitudes`;
      if(primera_pagina){
        this.page = 1
      }
      let data = {
        roles: this.user.roles_ids,
        users_id: this.user.id,
        areas_ids: this.user.areas_ids,
        page: this.page,
        folio: this.folio_filtred,
        inicio:this.inicio,
        termino:this.termino
        //areasId: this.user.areas_ids,
        //userId: this.user.id
      };
      await this.axios
        .post(url,data ,this.headers)
        .then((response) => {
          //this.listBody = response.data.data.filter(d=>this.user.areas_ids.includes(d.areas_id));
          this.load_data = true;
          let data = [...this.listBody];
          //this.page = response.data.current_page
          data =
            this.page != 1
              ? data.concat(response.data.data)
              : response.data.data;
          this.listBody = data;
          //this.$set(this.listBody,data);
          // this.page == 1?
          //     this.listBody = response.data.data:
          //     this.listBody = this.listBody.concat(response.data.data);

          this.last_page = response.data.last_page;
          this.ocultarLoading();
        })
        .catch((error) => {
          console.log(error);
          this.ocultarLoading();
        });
      this.spinner = false;
    },
    limpiar() {
      this.inicio = null;
      this.termino = null;
      this.folio_filtred = "";
      this.data_filtered = false;
      this.listBody = []
      this.page = 1
      this.loadSolicitudMateriales();
    },
    loadSolicitudMaterialesFiltred() {
      this.inicio == null && this.termino == null && this.folio_filtred == ""
        ? new Promise(() => {
            this.page = 1;
            this.loadSolicitudMateriales();
          })
        : new Promise(() => {
            if (
              this.inicio == null &&
              this.termino != null &&
              this.folio_filtred == ""
            ) {
              return;
            }
            this.spinner = true;
            let url = `${this.base_url}solicitudes/dates`;
            let data = {
              inicio: this.inicio,
              termino: this.termino,
              folio: this.folio_filtred,
              roles: this.user.roles_ids,
              estado_solicitud: 0,
              areasId: this.user.areas_ids,
              userId: this.user.id,
            };
            this.data_filtered = true;
            this.axios
              .post(url, data, this.headers)
              .then((response) => {
                this.listBody = response.data.data;
                this.ocultarLoading();
                this.spinner = false;
              })
              .catch((error) => {
                console.log(error);
                this.ocultarLoading();
                this.spinner = false;
              });
          });
    },
    async saveSolicitudMaterial() {
      this.spinner = true;
      // validaciones
      this.errors = [];
      if (!this.item.nombre) {
        this.errors.push("Debe indicar nombre del material");
      }
      if (!this.item.categorias_id) {
        this.errors.push("Debe seleccionar una categoria");
      }
      if (!this.item.unidad_medida) {
        this.errors.push("Debe seleccionar una categoria");
      }
      if (this.errors.length > 0) {
        this.validation_message = this.errors.join();
        this.showSnackBar(true);
        return;
      }
      let url = `${this.base_url}solicitud_materiales`;
      let data = {
        nombre: this.item.nombre,
        categorias_id: this.item.categorias_id,
        unidad_medida: this.item.unidad_medida,
      };
      if (this.item.id > 0) {
        url = url + "/" + this.item.id;
        await this.axios
          .put(url, data, this.headers)
          .then((response) => {
            let index = this.listBody.findIndex((i) => i.id == this.item.id);
            this.listBody.splice(index, 1, response.data);
            this.showSnackBar(false);
          })
          .catch((error) => {
            this.validation_message = error;
            this.showSnackBar(true);
          });
      } else {
        await this.axios
          .post(url, data, this.headers)
          .then((response) => {
            this.listBody.push(response.data);
            this.showSnackBar(false);
          })
          .catch((error) => {
            this.errors = error.response.data;
            this.validation_message = this.errors.join();
            this.showSnackBar(true);
          });
      }
      this.cleanForm();
      this.spinner = false;
    },
    editSolicitudMaterial(item) {
      this.spinner = true;
      this.index = this.listBody.findIndex((a) => a.id == item.id);
      this.item = Object.assign({}, item);
      this.dialog = true;
      this.title = "Editar Material";
      this.spinner = false;
    },
    async deleteSolicitudMaterial(item) {
      this.spinner = true;
      this.$confirm("Desea borrar este material?").then((res) => {
        if (res) {
          let url = `${this.base_url}solicitudes/${item.id}`;
          this.axios
            .delete(url, this.headers)
            .then((response) => {
              console.log(response);
              this.loadSolicitudMateriales();
              this.showSnackBar(false);
            })
            .catch((error) => {
              this.validation_message = error;
              this.showSnackBar(true);
            });
        }
      });
      this.spinner = false;
    },
    agregarSolicitud() {
      this.$router.push("crear_solicitud");
    },
    showSnackBar(error) {
      if (error) {
        this.color = "red";
      } else {
        this.color = "green";
        this.validation_message = "Ejecutado exitosamente!";
      }
      this.snackbar = true;
    },
    closeModal() {
      this.cleanForm();
      this.dialog = false;
      this.error = null;
      this.title = "Nueva Área";
    },
    cleanForm() {
      this.item.id = 0;
      this.item.nombre = "";
      this.item.categorias_id = null;
      this.item.unidad_medida = "";
      this.dialog = false;
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    },
    columnaVisible(type) {
      // console.log(this.user.roles_ids);
      const roles = this.user.roles_ids.includes(1) ? [8] : this.user.roles_ids;
      const rol_mas_alto = Math.max(...roles);
      const find = this.data_header.find((d) => d.value === type);
      return find.visible.includes(rol_mas_alto);
    },
    ...mapMutations(["actualizarBaseUrl", "mostrarLoading", "ocultarLoading"]),
  },
};
</script>
<style scoped>
th {
  background-color: #355f94 !important;
  color: #fff !important;
  border: 1px solid #fff;
}

/* Hace que el encabezado sea fijo */
.table-container {
  overflow-y: auto;
  /* Agrega una barra de desplazamiento vertical si es necesario */
}

.table-container thead {
  position: sticky;
  top: 0;
  z-index: 1;
  /* Asegura que el encabezado esté por encima del contenido desplazable */
}

.mx-1 {
  height: 40px;
  width: 40px;
}
.smaller-icon {
  font-size: 20px;
}
</style>
